<template>
  <main class="mainlayout">
 
    <Sidebar/> 

    <div class="content">  
      <button class="insead autowidth large" @click="addRow">Add New Experience</button>
      <br>  
      <br>
      <input v-model.trim="searchTerm" type="text" :disabled="loading" autocomplete="off" placeholder="Search..." /> 
      <br>
      <br>      
      <VueGoodTable
      ref="datatable"
      :columns="columns"
      :rows="experiences"
      striped
      styleClass="vgt-table striped"
      :search-options="{
        enabled: false,
        externalQuery: searchTerm
      }"
      :select-options="{ 
        enabled: true,
        selectOnCheckboxOnly: true 
      }">        
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'" class="rowActions">
            <button class="insead autowidth right" @click="editRow(props.row.id)">Rename</button>
            <button class="insead autowidth right" @click="$router.push('authoring/master/' + props.row.id)">Edit</button>
            <!-- <button class="insead autowidth right" :disabled="props.row.count > 0" :title="props.row.count > 0 ? 'Used in sessions, cannot remove.' : ''" @click="deleteRow(props.row.id)">Delete</button> -->
          </span>      
          <span v-else-if="props.column.field == 'updated'">
            {{ props.row.updated ? new Date(props.row.updated).toLocaleString() : '-' }}
          </span>
          <span v-else-if="props.column.field == 'created'">
            {{ new Date(props.row.created).toLocaleString() }}
          </span>
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
      </VueGoodTable>

      <span class="spinner relative" v-if="loading" />
    </div>

    <Modal v-if="showModal" @close="showModal = false; error = ''" class="experiencemodal">      
      <h3 slot="header" v-if="tempExperience.id">Rename Content Master #{{tempExperience.id}}</h3>      
      <h3 slot="header" v-else>Add new content master</h3>
      <div slot="body">        
        <label>Name</label>
        <input v-model.trim="tempExperience.name" type="text" placeholder="experience name"> 
        <div class="formerror" style="clear:both;" v-if="error">{{error}}</div>
      </div>
      <div slot="footer">
        <button class="insead" v-if="tempExperience.id" :class="{disabled: modalButtonDisabled}" @click="updateContentMaster" :disabled="modalButtonDisabled">Update</button>
        <button class="insead" v-else :class="{disabled: modalButtonDisabled}" @click="addContentMaster" :disabled="modalButtonDisabled">Add</button>        
      </div>
    </Modal>   
  </main>
</template>

<script>
import axios from 'axios';
import { VueGoodTable } from 'vue-good-table'
import Modal from '@/components/Modal.vue'
import Sidebar from '@/components/Sidebar.vue'

export default {
  name: 'Authoring',
  data: function(){
    return {
      columns: [
        {
          label: 'Id',
          field: 'id',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Updated',
          field: 'updated',
        },
        {
          label: 'Created',
          field: 'created',
        },       
        {
          label: 'Actions',
          field: 'actions'
        },
      ],      
      experiences: [],
      loading: false,
      error: undefined,
      searchTerm: undefined,
      showModal: false,   
      tempExperience: {
        id: undefined,
        name: undefined
      }
    }
  },
  computed: {
    selectedExperiences(){
      return this.$refs.datatable.selectedRows;
    },
    modalButtonDisabled(){
      return this.loading|| !this.tempExperience.name
    }
  },
  methods: {       
    async editRow(id){      
      this.tempExperience = JSON.parse(JSON.stringify(this.experiences.find(s => s.id == id))) 
      this.showModal = true
    },
    async addRow(){      
      this.tempExperience = {
        id: undefined,
        name: undefined
      } 
      this.showModal = true
    },
    async updateContentMaster(){    
      try{
        this.error = undefined;
        this.$nprogress.start();
        this.loading = true;
        
        await axios({ url: `content/master/${this.tempExperience.id}`, data: this.tempExperience, method: "POST" }) 
        let tmp = this.experiences.find(s => s.id == this.tempExperience.id)      
        tmp.name = this.tempExperience.name
        this.showModal = false
      }
      catch(err){
        console.log("Error when updating experience: " + err.response);
        this.error = err;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }        
    },
    async addContentMaster(){    
      try{
        this.error = undefined;
        this.$nprogress.start();
        this.loading = true;
        
        let resp = await axios({ url: `content/master`, data: this.tempExperience, method: "POST" })    
        this.experiences.push(resp.data)
        this.showModal = false
      }
      catch(err){
        console.log("Error when adding new experience: " + err.response);
        this.error = err;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }        
    },
    async getContentMasters(){      
        let resp = await axios({ url: "content/master" }) 
        this.experiences = resp.data;      
    }
  },  
  components: {
    VueGoodTable, 
    Modal,
    Sidebar
  },
  mounted() {
    try{
      this.$nprogress.start();
      this.loading = true;
      this.getContentMasters();
    }
    finally{
      this.loading = false;
      this.$nprogress.done();
    }
  },
  created() {
  }
}
</script>

<style lang="scss">


.rowActions{
  display: flex;
  opacity: 0.0;
}

.vgt-table tr:hover .rowActions{
  opacity: 1;
}

.modal-container{
  width: 600px;
}

.modal-footer{
  button.insead{
    width: 100%;    
  }
}

</style>
